
import { useStore } from "vuex";
import { getToken, ID_TOKEN_KEY } from "@/core/services/JwtService";
import { Actions } from "@/store/enums/StoreEnums";
import { defineComponent, onMounted } from "vue";
import TableWidget14 from "@/components/widgets/tables/Widget14.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "printee-orders",
  components: {
    TableWidget14,
  },
  setup() {
    const store = useStore();
    const token = getToken(ID_TOKEN_KEY);
    store.dispatch(Actions.LOGIN_TOKEN_CHANGED, token);

    onMounted(() => {
      setCurrentPageTitle("Printee orders");
    });
  },
});
