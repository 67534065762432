<template>
  <!--begin::PrinteeOrders-->
  <div class="row gy-5 gx-xl-8">
    <div class="col-xxl-12">
      <TableWidget14
        widget-classes="card-xxl-stretch mb-5 mb-xl-8"
      ></TableWidget14>
    </div>
  </div>
  <!--end::PrinteeOrders-->
</template>

<script lang="ts">
import { useStore } from "vuex";
import { getToken, ID_TOKEN_KEY } from "@/core/services/JwtService";
import { Actions } from "@/store/enums/StoreEnums";
import { defineComponent, onMounted } from "vue";
import TableWidget14 from "@/components/widgets/tables/Widget14.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "printee-orders",
  components: {
    TableWidget14,
  },
  setup() {
    const store = useStore();
    const token = getToken(ID_TOKEN_KEY);
    store.dispatch(Actions.LOGIN_TOKEN_CHANGED, token);

    onMounted(() => {
      setCurrentPageTitle("Printee orders");
    });
  },
});
</script>
